import "./MobileMenu.scss";
import { Link } from "react-scroll";
import logo from "./../../Assets/Images/Creativityunlocked.png";
import closeIcon from "./../../Assets/Icons/close.svg";
import daimonMeta from "./../../Assets/Images/MetaDaimon.svg";
const MobileMenu = ({ toggleMobileMenu }) => (
  <div className="mobile-menu-wrapper">
    <div className="mobile-menu-header">
      <div className="mobile-menu-logobox">
        <img src={logo} alt="creativity logo" width="110px" height="40px" />;
      </div>
      <div className="mobile-icon" onClick={toggleMobileMenu}>
        <img src={closeIcon} alt="close button" />;
      </div>
    </div>
    <nav className="mobile-navigation-menu">
      <ul className="mobile-navigation-list">
        <li className="mobile-navigation-list-item">
          <Link activeClass="active" to="Header" onClick={toggleMobileMenu}>
            di cosa parliamo
          </Link>
        </li>
        <li className="mobile-navigation-list-item">
          <Link to="Branding" onClick={toggleMobileMenu}>
            PERFORMANCE BRANDING
          </Link>
        </li>
        <li className="mobile-navigation-list-item">
          <Link to="Formula" onClick={toggleMobileMenu}>
            Formula dI crescita
          </Link>
        </li>
        <li className="mobile-navigation-list-item">
          <Link to="Relatori" onClick={toggleMobileMenu}>
            RELATORI
          </Link>
        </li>
        <li className="mobile-navigation-list-item">
          <Link to="contact" onClick={toggleMobileMenu}>
            CONTATTACI
          </Link>
        </li>
      </ul>
      <div className="mobile-navigation-menu-footer">
        <div className="mobile-navigation-menu-footer-logo">
          <img src={daimonMeta} alt="daimon meta logo" />;
        </div>
      </div>
    </nav>
  </div>
);

export default MobileMenu;
