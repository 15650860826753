import { Col, Container, Row } from "reactstrap";
import "./Declina.scss";
import MobileSlider from "../MobileSlider/MobileSlider";
import McDonald_1 from "../../Assets/Images/MobileSlider/Creativita/2.1_McDonald_s.gif";
import McDonald_2 from "../../Assets/Images/MobileSlider/Creativita/2.2_McDonald_s.gif";
import McDonald_3 from "../../Assets/Images/MobileSlider/Creativita/2.3_McDonald_s.gif";
import McDonald_1_png from "../../Assets/Images/MobileSlider/Creativita/2.1_McDonald_s.png";
import McDonald_2_png from "../../Assets/Images/MobileSlider/Creativita/2.2_McDonald_s.png";
import McDonald_3_png from "../../Assets/Images/MobileSlider/Creativita/2.3_McDonald_s.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { checkMobile } from "../../utils/Utils";
export default function Declina() {
  const imagesSlider = [
    { gif: McDonald_1, png: McDonald_1_png },
    { gif: McDonald_2, png: McDonald_2_png },
    { gif: McDonald_3, png: McDonald_3_png },
  ];
  return (
    <div className="Declina pad-Left">
      {/* <Container> */}
        <Row>
          {!checkMobile()&&
          <Col xs="12" lg="5">
            <div className="mobile">
              <MobileSlider Images={imagesSlider} dir="rtl" />
            </div>
          </Col>
          }
          <Col xs="12" lg="7">
            <div className="content overflow-hidden">
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  >
                <h4 className="subtitle">
                  Declina il messaggio <br /> vincente
                </h4>
              </AnimationOnScroll>

              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100} >
                <p className="paragraph">
                McDonald’s ha continuato a 
                  <strong> esplorare il concept creativo più performante</strong>
                  , personalizzando i messaggi in base a bisogni, community e
                  stagionalità. Con questa campagna, il brand è riuscito a
                  convertire un nuovo pubblico,{" "}
                  <strong>
                    incrementando il numero di app download e riducendo il CPA.
                  </strong>
                </p>
              </AnimationOnScroll>
            </div>
          </Col>
          {checkMobile()&&
          <Col xs="12" lg="6">
            <div className="mobile">
              <MobileSlider Images={imagesSlider} dir="rtl" />
            </div>
          </Col>
          }
        </Row>
      {/* </Container> */}
    </div>
  );
}
