// check if user in mobile or web 
export const checkMobile = () => {
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //     // true for mobile device
    //     return true
    // }else{
    //     return false
    // }
    if(window.innerWidth <= 991){
        return true
    }else{
        return false
    }
}
// for cursor animation
export const MouseEntered = () => {
    document.querySelector('.cursor-dot-outline').style.transform = 'translate(-50%, -50%) scale(1.5)'
  }
export const MouseLeave = () => {
document.querySelector('.cursor-dot-outline').style.transform = 'translate(-50%, -50%) scale(1)'
}
export const MouseEnteredLinks = () => {
    document.querySelector('.cursor-dot-outline').style.transform = 'translate(-50%, -50%) scale(.6)'
}

export function createScrollListener(element, scroll, callback, timeout) {
    let handle = null;
    const onScroll = function () {
        scroll();
        if (handle) {
            clearTimeout(handle);
        }
        handle = setTimeout(callback, timeout || 400); // default 200 ms
    };
    element.addEventListener('scroll', onScroll);
    return function () {
        element.removeEventListener('scroll', onScroll);
    };
}
  // handle submit form button
export const HandleDoneSubmit = (button , text , doneText , showDone=true) => {
button.current.innerHTML = `<img src="/checked.svg" class='markChecked' /> ${doneText}`
button.current.classList.add('Done-Submit');
if(showDone){
    setTimeout(() => {
        button.current.innerHTML = `${text}`
        button.current.classList.remove('Done-Submit');
    }, 2000);
}
}

