import "./Team.scss";
import { Container } from "reactstrap";
import elenaImage from "./../../Assets/Images/elena.gif";
import patriciaImage from "./../../Assets/Images/patricia.gif";
import nicolaImage from "./../../Assets/Images/nicola.gif";
import federicoImage from "./../../Assets/Images/federico.gif";
import StaffCard from "./StaffCard/StaffCard";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { checkMobile } from "../../utils/Utils";

const TeamSection = () => {
  return (
    <section className="team mt-5" id="Relatori">
      <Container className="overflow-hidden">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight animate__slower">
          <h2 className="title-sections-primary">Relatori</h2>
        </AnimationOnScroll>

        <div className="section-content">
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeIn"}  >
          <StaffCard
            title="PATRICIA CONSONNI"
            description={`Creative Agency Partner  \n at Meta`}
            img={patriciaImage}
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeIn"}  delay={100}>
        <StaffCard
            title="Elena salemi"
            description={`Head of Daimon \n Brand Experience Studio`}
            img={elenaImage}
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeIn"}  delay={200}>
          <StaffCard
            title="NICOLA DONATI"
            description={`PR & Communications \n Manager at Webranking`}
            img={nicolaImage}
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeIn"}  delay={300}>
          <StaffCard
            title="FEDERICO CAVAGLIÀ"
            description={`Digital Marketing Strategist \n at Webranking`}
            img={federicoImage}
          />
        </AnimationOnScroll>
        </div>
      </Container>
    </section>
  );
};

export default TeamSection;
