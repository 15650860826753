import "./../../Assets/GlobalStyles/GlobalStyles.scss";
import "./Footer.scss";
import facebook from "./../../Assets/Icons/Facebook.svg";
import instagram from "./../../Assets/Icons/instagram.svg";
import linkedin from "./../../Assets/Icons/Linkedin.svg";
import youtube from "./../../Assets/Icons/Youtube.svg";
import daimonMeta from "./../../Assets/Images/MetaDaimon.svg";
import footerLogo from "./../../Assets/Icons/DaimonpartofW.svg";
import { useEffect, useRef, useState } from "react";
import { checkMobile, HandleDoneSubmit, MouseEnteredLinks, MouseLeave } from "../../utils/Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function Footer() {
  const [email, setEmail] = useState("");
  const [subscribeMsg , setSubscribeMsg] = useState('');
  const [DisabledBTN , setDisableBTN] = useState(false)

  const submitBTN = useRef(false);
  const subscribe = (e) => {
    e.preventDefault();
    setDisableBTN(true)
    fetch("https://creativity-unlocked-backend.webranking.biz/", {
      method: "POST", 
      body: JSON.stringify({form:'newsletter' , email}),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        setDisableBTN(false)
        setSubscribeMsg(data.message)
        setEmail('');
        HandleDoneSubmit(submitBTN , 'iscrivimi alla newsletter' ,"INVIATO" )
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisableBTN(false)
      });
  };
  return (
    <footer>
      <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeIn" : "animate__fadeIn"} >
        <div className="container">
          <img src={daimonMeta} alt="Daimon meta written" />
          <div className="footer__top-content">
            <div className="footer_top-content-container">
              <h6 className="footer__heading">Chi siamo</h6>
              <p className="footer__paragraph">
                Siamo Daimon, il Brand Experience Studio di Webranking.
               { !checkMobile() && <br />} Ideiamo e realizziamo  esperienze multicanale attraverso{" "}
               { !checkMobile() && <br />}un  approccio nativo digitale.
              </p>
              <a href="https://daimon.agency/" target='_blank' rel="noreferrer">
                <button className="outlined-button"
                onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave}
                >SCOPRI DAIMON</button>
              </a>

              <div className="footer__social-icons">
                <a href="https://www.facebook.com/daimonagency" target='_blank'  rel="noreferrer">
                  <img src={facebook} alt="facebook icon" />
                </a>
                <a href="https://www.instagram.com/daimon_experience_studio/" target='_blank'  rel="noreferrer">
                  <img src={instagram} alt="instagram icon" />
                </a>
                <a href="https://www.linkedin.com/company/daimon-milano" target='_blank'  rel="noreferrer">
                  <img src={linkedin} alt="linkedin icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCenZOritdOzQeJveY3zQPQQ" target='_blank'  rel="noreferrer">
                  <img src={youtube} alt="youtube icon" />
                </a>
              </div>
            </div>
            <div className="footer__form-container">
              <h6 className="footer__heading">
                Iscriviti alla newsletter di Daimon
              </h6>
              <form>
                <div className="form-group">
                  <input type="email" id="email" placeholder="La tua email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  <label className="form-label" htmlFor="email">
                    EMAIL*
                  </label>
                </div>
                {subscribeMsg.length > 1 &&<p className="paragraph">{subscribeMsg}</p>}
                <button className="filled-button" onClick={subscribe} 
                  onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave}
                  ref={submitBTN}
                  disabled={DisabledBTN}
                >
                  iscrivimi alla newsletter
                </button>
              </form>
              <p className="footer__paragraph form-paragraph mt-2">
                Cliccando “Iscrivimi alla newsletter” dichiari di aver letto e
                accettato l
                <a href="https://daimon.agency/privacy-policy-newsletter/" target='_blank'  rel="noreferrer">
                  'Informativa Privacy
                </a>
              </p>
            </div>
          </div>
          <div className="footer__bottom-content">
            <div className="footer__contact-information">
              <p>Milano - Piazza IV Novembre, 1</p>
              <p>HQ: Correggio (RE) - Via Oratorio, 12A</p>
              <p>Tel: 0522.631.642</p>
              <p>PI: IT01779850351</p>
            </div>
            <figure className="footer__logo">
                <img src={footerLogo} alt="daimon part of webranking(logo)" />    
            </figure>
          </div>
          <div className="footer__navigation-links">
          <div>
            <a href="https://webranking.it/careers/" target="_blank"  rel="noreferrer" >
              Careers
            </a>
            <a href="https://daimon.agency/privacy-policy/" target="_blank"  rel="noreferrer">
              Privacy Policy
            </a>
            <a href="https://daimon.agency/cookie-policy/" target="_blank"  rel="noreferrer">
              Cookie Policy
            </a>
          </div>
          <span
            className="webrankingLink"
           
          >
            © 1998-2022 Webranking Srl
          </span>
          </div>
        </div>
      </AnimationOnScroll>
    </footer>
  );
}
