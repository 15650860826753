import { useState  , useEffect} from "react";
import { Col, Container, Row } from "reactstrap";
import { checkMobile  , createScrollListener} from "../../utils/Utils";
import MobileSlider from "../MobileSlider/MobileSlider";
import McDonald_1 from "../../Assets/Images/MobileSlider/Creativita/1.1_McDonald_s.gif";
import McDonald_2 from "../../Assets/Images/MobileSlider/Creativita/1.2_McDonald_s.gif";
import McDonald_3 from "../../Assets/Images/MobileSlider/Creativita/1.3_McDonald_s.gif";
import McDonald_1_png from "../../Assets/Images/MobileSlider/Creativita/1.1_McDonald_s.png";
import McDonald_2_png from "../../Assets/Images/MobileSlider/Creativita/1.2_McDonald_s.png";
import McDonald_3_png from "../../Assets/Images/MobileSlider/Creativita/1.3_McDonald_s.png";
import "./Creativita.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import LightBg from "../../Assets/Images/background/05_bg-min.png";
import LightBgTwo from "../../Assets/Images/background/06_bg-min.png";

export default function Creativita() {
  const imagesSlider = [
    { gif: McDonald_1, png: McDonald_1_png },
    { gif: McDonald_2, png: McDonald_2_png },
    { gif: McDonald_3, png: McDonald_3_png },
  ];
    // for animate light images 
    const [animateClass, setAnimateClass] = useState('');
    const [isScrolling, setScrolling] = useState(false);
    createScrollListener(window, function () {
      setScrolling(true);
    });
    useEffect(() => {
      if (isScrolling) {
          setAnimateClass('fadeInUp');
      } else {
          setAnimateClass('');
      }
    }, [isScrolling]);

  return (
    <div className="Creativita pad-Left ">
      <div className="light-bg">
        <img src={LightBg} alt="light-background" className={animateClass} onAnimationEnd={() => setScrolling(false)}  />
      </div>
      <div className="light-bg light-bg-Two">
        <img src={LightBgTwo} alt="light-background" className={animateClass} onAnimationEnd={() => setScrolling(false)}  />
      </div>
      {/* <Container className="overflow-hidden"> */}
        <div className="header">
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} >
            <span className="caption">CASE STUDY META</span>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100} >
            <h3 className="title">
              CREATIVITÀ DI SUCCESSO {!checkMobile() && <br />} PER MCDONALD’S
            </h3>
          </AnimationOnScroll>
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200} >
            <p className="paragraph">
              Per incrementare il download dell’app in Svezia, McDonald’s decide
              di&nbsp;
              <strong>
                 partire {!checkMobile() && <br />} dalla creatività guidata
                dalle motivazioni&nbsp;
              </strong>
               che spingono gli svedesi a scegliere {!checkMobile() && <br />}il
              brand.
            </p>
          </AnimationOnScroll>
        </div>
        <Row>
          {!checkMobile() && (
            <Col xs="12" lg="6">
              <div className="content">
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  >
                  <h4 className="subtitle">Testa le creatività </h4>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  delay={100}>
                  <p className="paragraph">
                    McDonald’s ha sviluppato
                    <strong> 4 asset creativi con messaggi diversi </strong>
                    <br />e le ha inserite in uno{" "}
                    <strong> split test su target ampio</strong>, in rotazione
                    <br />
                    con la creatività già in utilizzo. Le metriche di
                    performance hanno <br /> indicato il messaggio{" "}
                    <em>Break up/Make up Burger </em>come quello che <br /> ha
                    ottenuto i risultati migliori.
                  </p>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200} >
                <div className="DownApp">
                  <span>+627</span>
                  <span className="paragraph">
                    APP DOWNLOAD DELLA CREATIVITà <br />
                    BREAK/MAKE UP BURGER
                  </span>
                </div>
              </AnimationOnScroll>
            </Col>
          )}
          <Col xs="12" lg="6">
            <MobileSlider Images={imagesSlider} />
          </Col>
          {checkMobile() && (
            <Col xs="12" lg="6">
              <div className="content">
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  >
                  <h4 className="subtitle">Testa le creatività </h4>
                </AnimationOnScroll>
                <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  delay={100}>
                  <p className="paragraph">
                    McDonald’s ha sviluppato{" "}
                    <strong>4 asset creativi con messaggi diversi</strong> e le
                    ha inserite in uno{" "}
                    <strong>split test su target ampio</strong>, in rotazione
                    con la creatività già in utilizzo. Le metriche di
                    performance hanno indicato il messaggio Break up Burger come
                    quello che ha ottenuto i risultati migliori.
                  </p>
                </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200} >
                <div className="DownApp">
                  <span>+627</span>
                  <span className="paragraph">
                    APP DOWNLOAD DELLA CREATIVITà <br />
                    BREAK/MAKE UP BURGER
                  </span>
                </div>
              </AnimationOnScroll>
            </Col>
          )}
        </Row>
      {/* </Container> */}
    </div>
  );
}
