import {Col, Container, Row} from "reactstrap";
import serratura2 from "../../Assets/Images/serratura2.png";
import VideosSlider from "../VideosSlider/VideosSlider";
import "./Branding.scss";
import {AnimationOnScroll} from "react-animation-on-scroll";
import LightBg from '../../Assets/Images/background/01_bg-min.png'
import {checkMobile, createScrollListener} from "../../utils/Utils";
import {useEffect, useRef, useState} from "react";

export default function Branding() {
    const videos = [
        'https://www.youtube.com/embed/AorMjZarYbg',
        'https://www.youtube.com/embed/Njb5zHChxEA',
        'https://www.youtube.com/embed/5I1EmrB_m9I'
    ];

    const bgRef = useRef();
    const [animateClass, setAnimateClass] = useState('');
    const [isScrolling, setScrolling] = useState(false);

    createScrollListener(window, function () {
        setScrolling(true);
    });

    useEffect(() => {
        if (isScrolling) {
            setAnimateClass('fadeInUp');
        } else {
            setAnimateClass('');
        }
    }, [isScrolling]);

    return (
        <div className="Branding" id="Branding">
            <div className="light-bg">
                <img src={LightBg} alt="light-background" ref={bgRef} className={animateClass}
                     onAnimationEnd={() => setScrolling(false)}/>
            </div>
            <Container>
                <Row>
                    <Col xs="12" lg="4">
                        <div className="leftImg">
                            <img src={serratura2} alt="Branding"/>
                        </div>
                    </Col>
                    <Col xs="12" lg="8">
                        <div className="Content overflow-hidden">
                            <AnimationOnScroll animateOnce={true}
                                               animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}>
                                <span className="caption">Capitolo 1</span>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true}
                                               animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}
                                               offset={500} delay={100}>
                                <h2 className="title">
                                    PERFORMANCE <br/> BRANDING
                                </h2>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateOnce={true}
                                               animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}
                                               delay={200}>
                                <p className="paragraph">

                                    Il modo in cui gli utenti interagiscono con i contenuti ha subito una profonda
                                    trasformazione. 
                                    Di conseguenza, anche la modalità in cui viene progettata la comunicazione si deve
                                    adattare a
                                    questo cambiamento. Oggi <strong>la creatività è diventata la chiave</strong> per
                                    costruire campagne advertising di successo.
                                </p>
                                <p className="paragraph">
                                    Impara come visual identity, best practice creative e copy
                                    impattanti
                                    possono <strong>sbloccare le performance di brand.</strong>
                                </p>
                            </AnimationOnScroll>
                        </div>
                    </Col>
                </Row>
            </Container>
            <VideosSlider videos={videos}/>
        </div>
    );
}
