import { useState,useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import "./Quiz.scss";
import QuizIcon from "../../Assets/Icons/Quiz.svg";
import QuizSlider from "./QuizSlider/QuizSlider";
import { AnimationOnScroll } from "react-animation-on-scroll";
import LightBg from "../../Assets/Images/background/07_bg-min.png";
import { checkMobile , createScrollListener } from "../../utils/Utils";

export default function Quiz() {
      // for animate light images 
      const [animateClass, setAnimateClass] = useState('');
      const [isScrolling, setScrolling] = useState(false);
      createScrollListener(window, function () {
        setScrolling(true);
      });
      useEffect(() => {
        if (isScrolling) {
            setAnimateClass('fadeInUp');
        } else {
            setAnimateClass('');
        }
      }, [isScrolling]);

  return (
    <div className="section-container Quiz pad-Left padding-mobile" id="Quiz">
      <div className="light-bg">
        <img src={LightBg} alt="light-background"  className={animateClass} onAnimationEnd={() => setScrolling(false)}  />
      </div>
      {/* <Container> */}
      <Row>
        <Col xs="12" xl="5">
          <div className="content overflow-hidden">
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  >
              <span className="caption">
                <img src={QuizIcon} alt="Quiz" width="45px" height="30px" /> quiz
                finale
              </span>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  delay={100}>
              <h4 className="subtitle">
          
                LE TUE CREATIVITÀ <br /> SONO PERFORMANTI?
              </h4>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}  >
              <p className="paragraph">
                <strong>1 minuto, 6 domande</strong>: verifica la maturità
                creativa dei tuoi contenuti.
              </p>
            </AnimationOnScroll>
          </div>
        </Col>
        <Col xs="12" xl="7">
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  >
            <QuizSlider />
          </AnimationOnScroll>
        </Col>
      </Row>
      {/* </Container> */}
    </div>
  );
}
