import { useEffect, useRef, useState } from "react";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  Nav,
  NavbarBrand,
} from "reactstrap";
import { Link } from "react-scroll";
import "./Navbar.scss";
import Logo from "../../Assets/Images/Creativityunlocked.png";
import creativtyLogo from "../../Assets/Icons/creativity-unlocked-text.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import MobileMenu from "../MobileMenu/MobileMenu";
import { MouseEnteredLinks, MouseLeave } from "../../utils/Utils";
export default function TopNav() {
  const [imageHovered, setImageHovered] = useState(false);
  const [isMobileMenuShow, setIsMobileMenuShow] = useState(false);
  const Menu = useRef(null);
  useEffect(() => {
    if (isMobileMenuShow) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
      window.addEventListener("scroll", () => {
        window.scrollY > 50
          ? Menu.current?.classList.add("linear")
          : Menu.current?.classList.remove("linear");
      });
    }
  }, [isMobileMenuShow]);

  const handleMobileMenu = () => {
    setIsMobileMenuShow((prevState) => !prevState);
  };
  if (isMobileMenuShow) {
    return <MobileMenu toggleMobileMenu={handleMobileMenu} />;
  }

  return (
    <div ref={Menu} className="Navbar">
      <Navbar dark expand="md">
        <NavbarBrand
          onMouseOver={() => setImageHovered(true)}
          onMouseOut={() => setImageHovered(false)}
          onClick={() => window.scrollTo(0,0)}
        >
          <img
              className="eye-logo"
              src={!imageHovered ? Logo : creativtyLogo}
              alt="logo"
              width="110px"
              height="40px"
            />
        </NavbarBrand>
        <NavbarToggler onClick={handleMobileMenu} />
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <NavItem  onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave} >
              <Link activeClass="active" to="Header" spy offset={-120} onClick={() => window.history.pushState('a', 'section Name', `/#Header`)}>
                di cosa parliamo
              </Link>
            </NavItem>
            <NavItem  onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave} >
              <Link to="Branding" spy offset={-120} onClick={() => window.history.pushState('a', 'section Name', `/#Branding`)}>
                performance branding
              </Link>
            </NavItem>
            <NavItem  onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave}>
              <Link to="Formula" spy offset={-120} onClick={() => window.history.pushState('a', 'section Name', `/#Formula`)}>
                formula di crescita
              </Link>
            </NavItem>
            <NavItem  onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave}>
              <Link to="Relatori" spy offset={-120} onClick={() => window.history.pushState('a', 'section Name', `/#Relatori`)}>
                relatori
              </Link>
            </NavItem>
            <NavItem  onMouseEnter={MouseEnteredLinks} onMouseLeave={MouseLeave}>
              <Link to="contact" spy offset={-120} onClick={() => window.history.pushState('a', 'section Name', `/#Contattaci`)}>
                Contattaci
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}
