import React from "react";
import { useState, useEffect } from "react";
import "./CreativityInteractionSection.scss";
import instagramStory from "./../../Assets/Images/01_Diadora_story.gif";
import facebookLookbook from "./../../Assets/Images/02_Diadora_Lookbook.gif";
import { AnimationOnScroll } from "react-animation-on-scroll";
import LightBg from "../../Assets/Images/background/02_bg-min.png";
import LightBgTwo from "../../Assets/Images/background/03_bg-min.png";
import { Container } from "reactstrap";
import { checkMobile , createScrollListener } from "../../utils/Utils";

export default function CreativityInteractionSection() {
  // for animate light images 
  const [animateClass, setAnimateClass] = useState('');
  const [isScrolling, setScrolling] = useState(false);
  createScrollListener(window, function () {
    setScrolling(true);
  });
  useEffect(() => {
    if (isScrolling) {
        setAnimateClass('fadeInUp');
    } else {
        setAnimateClass('');
    }
  }, [isScrolling]);
  
  const [mobileScreenViewsContainer, setMobileScreenViewsContainer] =
    useState(false);

  const resize = () => {
    if (window.innerWidth <= 770) {
      setMobileScreenViewsContainer(true);
    } else {
      setMobileScreenViewsContainer(false);
    }
  };
  useEffect(() => {
    window.addEventListener("load", resize);
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return (
    <section className=" creativity-section mb-5">
      <div className="light-bg">
        <img src={LightBg} alt="light-background"  className={animateClass} onAnimationEnd={() => setScrolling(false)} />
      </div>
      <div className="light-bg light-bgTwo">
        <img src={LightBgTwo} alt="light-background" className={animateClass} onAnimationEnd={() => setScrolling(false)}  />
      </div>

      <Container className="container-creativity">
        <div className="client-creativity-header">
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}>
          <p className="caption">CASE STUDY DAIMON</p>
        </AnimationOnScroll>

        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100}>
            <h2 className="title-sections-secondary">
            CREATIVITÀ INTERATTIVE PER DIADORA
            </h2>
          </AnimationOnScroll>

          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}>
            <p className="paragraph-section">
            L’obiettivo di Diadora era promuovere la sua più recente campagna di brand, armonizzando la tipica <strong>comunicazione di awareness</strong> con <strong>messaggi destinati alla conversion </strong> e coerenti con la grammatica{checkMobile() && <br/>} del canale di destinazione.
            </p>
          </AnimationOnScroll>
        </div>

        <div className="client-creativity-phone-wrapper">
          <div className="client-creativity-phone-wrapper-content margin-first-phone">
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} >
              <h4 className="phones-section-title">Instagram Stories</h4>
            </AnimationOnScroll>

            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100}>
              <p className="paragraph-section">
              A livello creativo e strategico, abbiamo sviluppato <strong>formati social nuovi e coinvolgenti</strong>, mantenendo lo storytelling coerente con il concept di campagna e con la visual identity di brand. Abbiamo sfruttato la<strong> funzionalità del tap </strong>delle Instagram Stories per invitare gli utenti a <strong>interagire in modo creativo</strong> con il<br/> contenuto.
              </p>
            </AnimationOnScroll>
          </div>
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}>
            <div className="client-creativity-phone-wrapper-image">
              <img src={instagramStory} alt="phone playing instagram story" />
            </div>
          </AnimationOnScroll>
        </div>

        <div className="client-creativity-phone-wrapper second-phone-wrapper">
          <div className="client-creativity-phone-wrapper-content margin-second-phone">
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} >
              <h4 className="phones-section-title">Facebook Collection</h4>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100}>
              <p className="paragraph-section">
              Grazie al formato Collection Lookbook su Facebook, abbiamo affiancato il video di campagna ai prodotti di Diadora, creando un<strong>’esperienza immersiva</strong> che permette di passare dalla {!checkMobile()&&<br/>}scoperta all’acquisto in modo lineare e rapido. Questa si è rivelata una scelta vincente: la collection è stata il <strong>formato più performante</strong> che ha guidato la quasi totalità del traffico al sito.
              </p>
            </AnimationOnScroll>

            {!mobileScreenViewsContainer && (
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}>
                  <div className="views-container">
                    <h6 className="title-sections-primary color-3EC9CC">59k</h6>
                    <p className="paragraph-section color-3EC9CC">
                      LANDING PAGE VIEWS <br /> IN UN MESE
                    </p>
                  </div>
              </AnimationOnScroll>
            )}
          </div>
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}>
            <div className="client-creativity-phone-wrapper-image">
              <img src={facebookLookbook} alt="phone playing facebook lookbook" />
            </div>
          </AnimationOnScroll>
        
        </div>
        {mobileScreenViewsContainer && (
          <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200} >
            <div className="views-container">
              <h6 className="title-sections-primary color-3EC9CC">59k</h6>
              <p className="paragraph-section color-3EC9CC">
                LANDING PAGE VIEWS IN UN MESE
              </p>
            </div>
          </AnimationOnScroll>
        )}
      </Container>
    </section>
  );
}
