import { useState , useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import bg_porta3 from "../../Assets/Images/bg_porta3.png";
import VideosSlider from "../VideosSlider/VideosSlider";
import "./Formula.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import LightBg from '../../Assets/Images/background/04_bg-min.png'
import { checkMobile  , createScrollListener} from "../../utils/Utils";

export default function Formula() {
  const videos = [
    'https://www.youtube.com/embed/o97O3dZe1IU',
    'https://www.youtube.com/embed/lL31II20E98',
    'https://www.youtube.com/embed/iS3SurWnZgo'
  ]
    // for animate light images 
    const [animateClass, setAnimateClass] = useState('');
    const [isScrolling, setScrolling] = useState(false);
    createScrollListener(window, function () {
      setScrolling(true);
    });
    useEffect(() => {
      if (isScrolling) {
          setAnimateClass('fadeInUp');
      } else {
          setAnimateClass('');
      }
    }, [isScrolling]);
  return (
    <div className="Formula" id="Formula">
    
      <div className="light-bg"> <img src={LightBg} alt="light-background" className={animateClass} onAnimationEnd={() => setScrolling(false)} /> </div>
      <Container>
        <Row>
          <Col xs="12" lg="4">
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}>
              <div className=" flexCenterRow leftImg">
                <img className="leftImg" src={bg_porta3} alt="Formula" />
              </div>
            </AnimationOnScroll>
          </Col>
          <Col xs="12" lg="8">
            <div className="Content overflow-hidden">
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}>
                <span className="caption">Capitolo 2</span>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100}>
                <h2 className="title">Formula di Crescita</h2>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}>
                <p className="paragraph">
                  A tutti i business capita, ad un certo punto, di raggiungere
                  una fase di stallo e continuare a
                  crescere diventa difficile. Quando le campagne non performano
                  più come prima, <strong>la chiave</strong> per
                  raggiungere i risultati sperati
                  <strong> è partire dalla creatività:</strong> testa diversi
                  asset creativi su un
                  pubblico ampio e individua il più performante.
                </p>
                <p className="paragraph">
                  Impara come diversi set di creatività possono{" "}
                  <strong>sbloccare nuovi clienti </strong>
                  e guidare la crescita del tuo brand.
                </p>
              </AnimationOnScroll>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="formula-slider-container">
        <VideosSlider videos={videos} />
      </div>
    </div>
  );
}
