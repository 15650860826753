import { useEffect, useRef } from "react";
import "./Hero.scss";
import MetaDomin from "../../Assets/Images/MetaDaimon.svg";
import SplashCreativity from "../../Assets/Images/splashpage_creativityunlocked.svg"
import DownArrow from "../../Assets/Icons/arrowDown.svg";
import { iOS } from "../../utils/Utils";
export default function Hero({ setShowHero, showHero }) {
  const HeroSection = useRef(null);
  // for hiding hero section 
  const HideHero = () => {
    HeroSection.current.classList.add('hideHero');
    setTimeout(() => {
      setShowHero(false)
    },490)
  }

  useEffect(() => {
    // for disktop
    HeroSection.current.addEventListener('wheel' , (e) => {
      HideHero();
      e.preventDefault();
      e.stopPropagation();
      return false;
    });
    // for mobile 
    HeroSection.current.addEventListener('touchmove' , (e) => {
      HideHero();
      e.preventDefault();
      e.stopPropagation();
      return false;
    });
    
  })

  return (
    <div className={`Hero`} ref={HeroSection}>
      <img className="logo" src={MetaDomin} alt="MetaDomin" />
      <div className="middle">
            <img src={SplashCreativity} alt="Creativity Unlocked" />
          {/* <img src={creativityUnlockedImageLogo} alt="Creativity Unlocked text" /> */}
      </div>
        <div className="last" onClick={HideHero}>
          <p>sBLOCCA Le TUe competenze IN 20 MINUTI</p>
          <img
            src={DownArrow}
            alt="continue"
          />
        </div>
    </div>
  );
}
