import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import MetaDaimon from "../../Assets/Images/MetaDaimon.svg";
import "./Header.scss";
import { checkMobile, MouseEntered, MouseLeave } from "../../utils/Utils";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactPlayer from 'react-player/lazy'


export default function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const VideoRef = useRef(null)
  useEffect(() => {
    // check mobile device
    setIsMobile(checkMobile());
    window.addEventListener("resize", () => {
      setIsMobile(checkMobile());
    });
    return () =>
      window.removeEventListener("resize", setIsMobile(checkMobile()));
  }, []);

  const HandleSlideClicked = (e) => {
    e.target.style.display ='none'
  };
  // Reset video onend  
  const OnEndVideo = () => {
    VideoRef.current.querySelector('iframe').contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*')
  }
  return (
    <div className="Header" id="Header">
      <Container>
        <Row>
          {!isMobile && (
            <Col xs="12" md="12" lg="4" xl="3">
              <div
                className="Video"
                onMouseEnter={MouseEntered}
                onMouseLeave={MouseLeave}
                ref={VideoRef}
              >
                <div className="overlay" onClick={HandleSlideClicked}></div>
                <ReactPlayer 
                url={'https://www.youtube.com/embed/AyFkFlmQPdU?rel=0'}
                width='100%'
                height='100%'
                controls={true}
                onEnded={OnEndVideo}
                />
              </div>
            </Col>
          )}
          <Col xs="12" lg={{ size: 7 }} xl={{  size: 8 }}>
            <div className="Content overflow-hidden">
              <AnimationOnScroll animateOnce={true}  animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} initiallyVisible={checkMobile()} >
                <img src={MetaDaimon} alt="MetaDaimon" width="235px" />
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100} initiallyVisible={checkMobile()}  >
                <h2 className="title">DI COSA PARLIAMO</h2>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200} initiallyVisible={checkMobile()} >
                <p className="paragraph ">
                  La creatività e il successo delle campagne di advertising sono
                  legate da una relazione di interdipendenza. Lo sanno bene Meta
                  e Daimon che hanno collaborato a questo progetto educational
                  che fornisce <strong>insight e best practice</strong> per
                  rendere le tue <strong>creatività più performanti.</strong>
                  <span>Inizia il percorso!</span>
                </p>
              </AnimationOnScroll>
            </div>
          </Col>
          {isMobile && (
            <Col xs="12" lg="4">
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={300}>
              <div className="flexCenterRow" >
                <div
                  className="Video"
                  onMouseEnter={MouseEntered}
                  onMouseLeave={MouseLeave}
                  ref={VideoRef}
                >
                  <div className="overlay" onClick={HandleSlideClicked}></div>
                  <ReactPlayer 
                    url={'https://www.youtube.com/embed/AyFkFlmQPdU?rel=0'}
                    width='100%'
                    height='100%'
                    controls={true}
                    onEnded={OnEndVideo}
                  />
                </div>
              </div>
            </AnimationOnScroll>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}
