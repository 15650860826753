import { useRef, useState } from "react";
import { Col, Container } from "reactstrap";
import lightIcon from "./../../Assets/Icons/light.svg";
import "./PathToGrowthSection.scss";
import DownArrow from "../../Assets/Icons/arrowDown.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { checkMobile, MouseEnteredLinks, MouseLeave } from "../../utils/Utils";
import Refresh from "../../Assets/Icons/Refresh.svg";

const paragraphsArray = [
  {
    title: "Le mie creatività non performano più come prima. COSA POSSO FARE?",
    text: "",
    icon: "",
    showed: true,
    paragraphActive: true,
  },
  {
    title: "Sviluppo un set di CREATIVITà guidate dalle motivazioni",
    text: "",
    icon: lightIcon,
    showed: false,
    paragraphActive: false,
  },
  {
    title: "",
    text: "Conduco uno split test con 3 nuove creatività + 1 asset già in utilizzo su target ampio",
    icon: "",
    showed: false,
    paragraphActive: false,
  },
  {
    title: "Sono riuscito a convertire una nuova audience?",
    text: "Declino la creatività più performante, altrimenti...",
    icon: lightIcon,
    showed: false,
    paragraphActive: false,
  },
  {
    title: "",
    text: "... verifico che le metriche di performance siano accettabili e ottimizzo la campagna",
    icon: "",
    showed: false,
    paragraphActive: false,
  },
  {
    title: "ATTENZIONE! Se le metriche NON sono accettabili, ricomincio dalla creatività",
    text: "",
    icon: lightIcon,
    showed: false,
    paragraphActive: false,
  },
  {
    title: "",
    text: "Trovo altre motivazioni o barriere all’acquisto per sviluppare un nuovo set creativo ed effettuare nuovi test",
    icon: "",
    showed: false,
    paragraphActive: false,
  },
];
const PathToGrowthSection = () => {
  const [paragraphsShowed, setParagraphsShowed] = useState(paragraphsArray);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [endOfParagraphs, setEndOfParagraphs] = useState(false);
  const ParsContainer = useRef(null);
  const changeParagraphVisibility = () => {
    const tempArray = [...paragraphsShowed];
    for (const paragraph of tempArray) {
      paragraph.paragraphActive = false;
      if (!paragraph.showed) {
        paragraph.showed = true;
        paragraph.paragraphActive = true;
        break;
      }
    }

    const checkIfallTrue = tempArray.every((paragraph) => paragraph.showed);
    if (checkIfallTrue) {
      setEndOfParagraphs(true);
    }
    return tempArray;
  };
  // animate pargraphs 
  const animatePars = () => {
    setTimeout(() => {
      ParsContainer.current.scrollTop = ParsContainer.current.scrollHeight;
      ParsContainer.current.querySelector('.active').classList.add('fadein')
    }, 50);
  }
  const showParagraphHandler = () => {
    setParagraphsShowed(changeParagraphVisibility());
    // to scroll bottom of the paragrphs container
    animatePars()
  };
  const showContinueButtonHandler = () => {
    const newArray = changeParagraphVisibility();
    setParagraphsShowed(newArray);
    setShowContinueButton(true);
    animatePars()
  };

  // rest Dalla pargraphs  
  const RestDalla = () => {
    setParagraphsShowed(paragraphsShowed.map((par , index) => {
      if(index === 0){
        return {...par , showed:true , paragraphActive:true}
      }else{
        return {...par , showed:false , paragraphActive:false}
      }
    }));
    setShowContinueButton(false);
    setEndOfParagraphs(false);

  }
  return (
    <section className="path-to-growth-section">
      <Container className="path-to-growth-section-wrapper">
        <Col xs="12" lg="6">
          <div className="overflow-hidden">
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} >
              <span className="caption">IL PERCORSO DI CRESCITA</span>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100}>
              <h3 className="title-sections-secondary">
              DALLA CREATIVITÀ ALLA CRESCITA
              </h3>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={200}>
              <p className="paragraph-section">
                La strada verso la crescita parte dalla creatività: 
                <strong> percorri tutti gli step </strong>
                per sviluppare asset creativi di successo e
                <strong> convertire nuovi clienti in  modo efficiente
                </strong>
                .
              </p>
            </AnimationOnScroll>
          </div>
        </Col>
        <Col xs="12" lg="6">
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}>
          <div className="action-section" ref={ParsContainer}>
            {paragraphsShowed.map(
              (paragraph, index) =>
                paragraph.showed && (
                  <div
                    key={index}
                    className={`action-section-paragraph ${
                      paragraph.paragraphActive && "active"
                    } `}
                  >
                    {paragraph.title && (
                      <p className="action-section-heading-paragraph">
                        {paragraph.icon && (
                          <img src={paragraph.icon} alt="paragraph_icon" />
                        )}

                        {paragraph.title && (
                          <span
                            className={`paragraph-title ${
                              paragraph.icon &&
                              index !== 0 &&
                              "margin-left-20 text-left"
                            }`}
                          >
                            {paragraph.title}
                          </span>
                        )}
                        <br />
                      </p>
                    )}
                    {paragraph.text && (
                      <span
                        className={`paragraph-text ${
                          paragraph.title &&
                          paragraph.text &&
                          "text-align-normal paragraph-text-margin"
                        }`}
                      >
                        {paragraph.text}
                      </span>
                    )}
                  </div>
                )
            )}
            {showContinueButton && !endOfParagraphs && (
              <button
                className="continue-button"
                onClick={showParagraphHandler}
                onMouseEnter={MouseEnteredLinks}
                onMouseLeave={MouseLeave}
              >
                SUCCESSIVO <img src={DownArrow} alt="yellow arrow" />
              </button>
            )}
            {!showContinueButton && (
              <button
                className="filled-button"
                onClick={showContinueButtonHandler}
                onMouseEnter={MouseEnteredLinks}
                onMouseLeave={MouseLeave}
              >
                INIZIO SUBITO
              </button>
            )}
            {paragraphsShowed[paragraphsShowed.length -1].paragraphActive && 
              <div
              className="restDalla flexCenterRow"
              onClick={RestDalla}
              onMouseEnter={MouseEnteredLinks}
              onMouseLeave={MouseLeave}
            >
              <span className="reset">
                Ricomincia <img src={Refresh} alt="Refresh" />
              </span>
            </div>
            }
          </div>
        </AnimationOnScroll>
        </Col>
      </Container>
    </section>
  );
};

export default PathToGrowthSection;
