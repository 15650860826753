import { useState, useEffect, useRef } from "react";
import { useField } from "../../hooks/useField";
import document from "./../../Assets/Icons/document.svg";
import "./ContactSection.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { checkMobile, HandleDoneSubmit, MouseEnteredLinks, MouseLeave } from "../../utils/Utils";
import { Container } from "reactstrap";

const generateRandomArrayNumbers = () => [
  Math.floor(Math.random() * 9 + 1),
  Math.floor(Math.random() * 9 + 1),
];

const ContactSection = () => {
  const SubmitBTN = useRef(null)
  const [isChecked, setIsChecked] = useState(false);
  const [DisabledBTN , setDisableBTN] = useState(false)
  const [randomArray, setRandomArray] = useState(generateRandomArrayNumbers());
  const [messageField, setMessageField] = useState("");

  const [questionField, setQuestionField, questionError, setQuestionError] =
    useField();
  const [email, setEmail, emailError, setEmailError] = useField();

  const validateQuestion = (num) => {
    return Number(num) === randomArray[0] + randomArray[1];
  };

  const changeEmailHandler = (e) => {
    setEmail(e.target.value);
    if (email.match("[a-z0-9]+@[a-z]+.[a-z]{2,3}")) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const questionChangeHandler = (e) => {
    if (validateQuestion(e.target.value)) {
      setQuestionField(Number(e.target.value));
      setQuestionError(false);
    } else {
      setQuestionError(true);
    }
  };

  const checkboxHandler = () => {
    setIsChecked((prevState) => !prevState);
  };

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    //validation
    if (!email.match("[a-z0-9]+@[a-z]+.[a-z]{2,3}")) {
      setEmailError(true);
    }
    if (!validateQuestion(questionField)) {
      setQuestionError(true);
      setRandomArray(generateRandomArrayNumbers());
    }

    if (emailError || questionError ) {
      return;
    }
    setDisableBTN(true)
    // handle api call
    fetch("https://creativity-unlocked-backend.webranking.biz/", {
      method: "POST",
      body: JSON.stringify({ form: "contact", email, message: messageField }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessageField("");
        setEmail("");
        setIsChecked(false);
        setQuestionField("");
        HandleDoneSubmit(SubmitBTN , "INVIA subito" , 'INVIATO');
        setDisableBTN(false)
        // setSubscribeMsg(data.message)
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisableBTN(false)
      });
  };

  const emailClasses = emailError ? `form-group invalid` : `form-group`;

  const questionClasses = questionError ? `form-group invalid` : `form-group`;

  return (
    <Container id="contact">
      <div className="contact-section-wrapper overflow-hidden">
        <AnimationOnScroll
          animateOnce={true}
          animateIn={
            checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"
          }
        >
          <span className="caption text-center">contattaci</span>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={
            checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"
          }
        >
          <h3 className="title-sections-secondary text-center">
          TI È PIACIUTO IL PROGETTO?
          </h3>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={
            checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"
          }
          delay={100}
        >
          <p className="paragraph-section text-center">
            <strong>Lasciaci un commento o un consiglio!</strong> 
          </p>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateOnce={true}
          animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeIn"}
          delay={150}
        >
          <div className="form-container">
            <div className="form-message">
      
              <img src={document} alt="white document" />
          
              <p className="document-paragraph">
                <strong>Riceverai il playbook di Creativity Unlocked</strong> per
                avere tutte le best practice creative sempre a portata di mano{" "}
              </p>
            
            </div>
            <form onSubmit={formSubmissionHandler}>
              <div className={emailClasses}>
                {emailError && (
                  <p className="invalid-paragraph">Inserisci un indirizzo email valido</p>
                )}
                <input
                  type="email"
                  id="email"
                  placeholder="La tua email"
                  onChange={changeEmailHandler}
                  onBlur={changeEmailHandler}
                  value={email}
                />
                <label className="form-label" htmlFor="email">
                
                  EMAIL*<span> (obbligatorio)</span>
               
                </label>
              </div>
              <div className="form-group">
                <textarea
                  id="textarea"
                  rows="6"
                  placeholder="Il tuo messaggio"
                  value={messageField}
                  onChange={(e) => setMessageField(e.currentTarget.value)}
                />
                <label className="form-label" htmlFor="textarea">
           
                  Cosa pensi di creativity unlocked?
         
                </label>
              </div>
              <div className={questionClasses}>
                {questionError && (
                  <p className="invalid-paragraph">Inserisci il risultato dell'operazione</p>
                )}
                <input
                  type="text"
                  id="question"
                  placeholder="Risposta"
                  onChange={questionChangeHandler}
                  onBlur={questionChangeHandler}
                  defaultValue={questionField}
                  key={questionField}
                />
                <label className="form-label" htmlFor="question">
           
                  quanto fa {randomArray[0]}+{randomArray[1]}?*
                  <span> (obbligatorio)</span>
               
                </label>
              </div>

              <div className="checkbox-container">
                <input className="hidden-checkbox" type="checkbox" />
                <div
                  className={`styled-checkbox ${isChecked && "checked"}`}
                  onClick={checkboxHandler}
                >
                  <svg
                    className={!isChecked ? "d-none" : "icon"}
                    viewBox="0 0 24 24"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>  
                </div>
                <label className="checkbox-label">

       
                  Voglio iscrivermi alla newsletter di Daimon e dichiaro di aver
                  letto e accettato l
                  <a
                    href="https://daimon.agency/privacy-policy-newsletter/" target="_blank"
                    rel="noreferrer"
                  >
                    'informativa Privacy
                  </a>
            
                </label>
              </div>
              <div className="form-action">
                <button
                  className="submit-button"
                  onMouseEnter={MouseEnteredLinks}
                  onMouseLeave={MouseLeave}
                  ref={SubmitBTN}
                  disabled={DisabledBTN}
                >
                  INVIA subito
                </button>
              </div>
            </form>
            <p className="privacy-info">

              Cliccando “Invia subito” dichiari di aver letto e accettato l
              <a
                href="https://daimon.agency/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                'informativa Privacy
              </a>
        
            </p>
          </div>
        </AnimationOnScroll>
      </div>
    </Container>
  );
};

export default ContactSection;
