import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import './VideosSlider.scss'
import ReactPlayer from 'react-player/lazy'

// import required modules
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { checkMobile, MouseEntered, MouseLeave } from "../../utils/Utils";

export default function VideosSlider({videos}) {
  const swiperRef = useRef(null);
   // handle click on slide (youtube icon ) to show the youtube video
    const HandleSlideClicked = (e) => {
      let Sliders = [...e.target.parentNode.parentNode.childNodes]
      Sliders.forEach(slide => {
        slide.firstChild.style.display = 'block';
        // to stop videos from playing when click on it
        slide.querySelector('iframe').contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
      });
      e.target.style.display ='none'
    }
    // edit cursour when entered video

  return (
    <div className="VideosSlider" >
        <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        centeredSlides={true}
        slideToClickedSlide={true}
        preventClicks={false}
        grabCursor={true}
        pagination={{
          clickable:false,
        }}
        onSlideChange={({ slides }) => {
           slides.forEach(slide => {
              slide.firstChild.style.display = 'block';
              // to stop videos from playing when click on it
              slide.querySelector('iframe').contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
            });
        }}
        modules={checkMobile()? [Pagination] : []}
        className="mySwiper"
        // breakpoints={{
        //   640: {
        //     slidesPerView: 'auto',
        //     spaceBetween: 0,
        //   },
        //   768: {
        //     slidesPerView: 3,
        //     spaceBetween: 40,
        //   },
        //   1024:{
        //     slidesPerView: 3,
        //     spaceBetween: 40,
        //   },
        //   1200: {
        //       slidesPerView: 3,
        //       spaceBetween: 50,
        //     },
        //   1400:{
        //     slidesPerView: "auto",
        //       spaceBetween: 10,
        //   }
        // }}
      >
      {videos.map((video , index) => (
        <SwiperSlide key={index}  onMouseEnter={MouseEntered} onMouseLeave={MouseLeave} >
              <div className="overlay" onClick={HandleSlideClicked}></div>
              <ReactPlayer 
                url={video}
                className='videoSlide'
                width='100%'
                height='100%'
                controls={true}
                // playing={activeSlide != index && false }
                onEnded={() => index+1&&swiperRef.current.slideTo(index+1)}
                />
        </SwiperSlide>
      ))}
      </Swiper>
    </div>
  )
}
