import { Swiper, SwiperSlide } from "swiper/react";
import './MobileSlider.scss'
import MobileFram from '../../Assets/Images/MobileSlider/mobileFrame.svg'
// import required modules
import { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function MobileSlider({Images , dir}) {
  return (
    <div className={`MobileSlider MobileSlider-${dir}`} dir={dir}>
        <img className={`mobileframe ${dir}`} src={MobileFram} alt="mobile frame" loading="lazy" />
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={10}
          centeredSlides={window.innerWidth < 769 ? true : false}
          dir={dir}
          autoplay={true}
          loop={true}
          onSlideChange={({slides}) => {
            slides.forEach((slide,index) => {
              setTimeout(() => {
                if(slide.classList.contains('swiper-slide-active')){
                    slide.firstChild.setAttribute('src' , [...Images , ...Images][index].gif )
                }else if([...Images , ...Images][index]){
                    slide.firstChild.setAttribute('src' , [...Images , ...Images][index].png)
                }
              },200)
            })
          }}
          pagination={{
            clickable: true,
          }}
        modules={[Pagination]}
        className="mobileSiwper"
        breakpoints={{
            // 640: {
            //     slidesPerView: 1,
            //     spaceBetween: 20,
            // },
            // 768: {
            //     slidesPerView: 1,
            //     spaceBetween: 40,
            // },
            // 1024: {
            //     slidesPerView: 1,
            //     spaceBetween: 50,
            // },
            // 1200: {
            //     slidesPerView: 2,
            //     spaceBetween: 50,
            // },
            // 1400:{
            //   slidesPerView: 2,
            //     spaceBetween: 0,
            // }
            // 1027: {
            //     slidesPerView: 1,
            //     spaceBetween: 0,
            // },
        }}
      >
        {Images.map((img,index) => 
          <SwiperSlide key={index} >
            <img src={img.png} alt="McDonald1" loading="lazy" />
        </SwiperSlide>
        )}
      </Swiper>
    </div>
  )
}
