import { Fragment, useEffect, useState } from "react";
import Cursor from "./Components/Cursor/Cursor";
import Branding from "./Components/BrandingSection/Branding";
import Creativita from "./Components/CreativitaSection/Creativita";
import Formula from "./Components/FormulaSection/Formula";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import KeysSection from "./Components/KeysSection/KeysSection";
import Navbar from "./Components/Navbar/Navbar";
import PathToGrowthSection from "./Components/PathToGrowthSection/PathToGrowthSection";
import TeamSection from "./Components/TeamSection/Team";
import ContactSection from "./Components/ContactSection/ContactSection";
import CreativityInteractionSection from "./Components/CreativityInteractionSection/CreativityInteractionSection";
import Declina from "./Components/DeclinaSection/Declina";
import Quiz from "./Components/QuizSection/Quiz";
import { checkMobile } from "./utils/Utils";
import { scroller } from "react-scroll";

function App() {
  const [showHero, setShowHero] = useState(true);
  useEffect(() => {
    // just check if the path container name of section or not
    if (
      window.location.href.split("#")[1] &&
      window.location.href.split("#")[1].length > 3
    ) {
      setShowHero(false);
      // scroll to speccific section when page loaded
      setTimeout(() => {
        scroller.scrollTo(window.location.href.split("#")[1], {
          duration: 100,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -100,
        });
      }, 500);
    }
    // mailchaimp popup if user close page before 60s
    const HandleBeforeUnload = (e) => {
      e.preventDefault();
      // check if pass 60 or not
      if (!checkMobile() && Math.round(performance.now() / 1000) < 60) {
        showPopup();
      }
    };
    if (window.innerWidth > 769) {
      window.addEventListener("beforeunload", HandleBeforeUnload);
      return window.removeEventListener("beforeunload", HandleBeforeUnload);
    }
  });

  //to restore position 0 on refresh
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    return (window.history.scrollRestoration = "manual");
  }, []);

  const showPopup = () => {
    // eslint-disable-next-line no-unused-expressions
    !(function (c, h, i, m, p) {
      // eslint-disable-next-line no-unused-expressions
      (m = c.createElement(h)),
        (p = c.getElementsByTagName(h)[0]),
        (m.async = 1),
        (m.src = i),
        p.parentNode.insertBefore(m, p);
    })(
      document,
      "script",
      "https://chimpstatic.com/mcjs-connected/js/users/faef13f8c201bb9f055dba72c/7b4f1df30a557ff033b216e6c.js"
    );
  };
  return (
    <Fragment>
      {showHero && <Hero setShowHero={setShowHero} showHero={showHero} />}
      <Navbar />
      <Header />
      <Branding />
      <CreativityInteractionSection />
      <Formula />
      <PathToGrowthSection />
      <Creativita />
      <Declina />
      <Quiz />
      <TeamSection />
      <KeysSection />
      <ContactSection />
      <Footer />
      {window.innerWidth > 992 && <Cursor />}
    </Fragment>
  );
}

export default App;
