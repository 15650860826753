import { useState , useEffect } from "react";
import "./KeysSection.scss";
import keyImage from "./../../Assets/Images/03_chiavi_alpha.gif";
import { AnimationOnScroll } from "react-animation-on-scroll";
import LightBg from '../../Assets/Images/background/08_bg-min.png'
import { checkMobile ,createScrollListener } from "../../utils/Utils";

const KeysSection = () => {
    // for animate light images 
    const [animateClass, setAnimateClass] = useState('');
    const [isScrolling, setScrolling] = useState(false);
    createScrollListener(window, function () {
      setScrolling(true);
    });
    useEffect(() => {
      if (isScrolling) {
          setAnimateClass('fadeInUp');
      } else {
          setAnimateClass('');
      }
    }, [isScrolling]);

  return (
    <section className="keys-section-wrapper">
      <div className="light-bg"> <img src={LightBg} alt="light-background" className={animateClass} onAnimationEnd={() => setScrolling(false)}  /> </div>
      <div className="keys-section-content overflow-hidden">
        <h3 className="keys-section-header">
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"}  >
            THE KEY TO PERFORM
          </AnimationOnScroll>
        </h3>
        <AnimationOnScroll animateOnce={true} animateIn={checkMobile() ? "animate__fadeInUp" : "animate__fadeInRight"} delay={100} >
          <div className="flexCenterRow">
            <img src={keyImage} alt="keys" />
          </div>
        </AnimationOnScroll>
      </div>
    </section>
  );
};

export default KeysSection;
