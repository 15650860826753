import "./StaffCard.scss";
const StaffCard = (props) => {
  return (
    <div className="staff-card-wrapper">
      <div className="staff-card-image">
        <img src={props.img} alt="person standing" loading="lazy" />
      </div>
      <div>
        <h5 className="staff-card-title">{props.title}</h5>
        <p className="staff-card-description">{props.description}</p>
      </div>
    </div>
  );
};

export default StaffCard;
