import { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import LockedIcon from "../../../Assets/Icons/Locked.svg";
import QuizIcon from "../../../Assets/Icons/Quiz.svg";
import QuizIcon_dark from "../../../Assets/Icons/Quiz_dark.svg";
import "./QuizSlider.scss";
import ArrowLeft from "../../../Assets/Icons/ArrowLeft.svg";
import ArrowRight from "../../../Assets/Icons/ArrowRight.svg";
import Refresh from "../../../Assets/Icons/Refresh.svg";
import Checked from '../../../Assets/Icons/checked.svg'
// import required modules
import { Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { HandleDoneSubmit, MouseEnteredLinks, MouseLeave } from "../../../utils/Utils";

export default function VideosSlider() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const SubmitBTN = useRef(null);
  const [DisabledBTN , setDisableBTN] = useState(false)
  const [allPoints, setAllPoints] = useState(0);
  const [email , setEmail] = useState('');
  const [error , setError] = useState('')
  const [Questions, setQuestions] = useState([
    {
      question:
        "Pensi che la visual identity del tuo brand sia riconoscibile e ben strutturata?",
      Choices: [
        "È poco definita: abbiamo solo il logo",
        "È ottimizzata: abbiamo un brandbook",
        "È ben strutturata: abbiamo delle style guide digitali",
      ],
      answer: "",
      icon: QuizIcon,
      point: 0,
    },
    {
      question:
        "Le tue creatività sono realizzate seguendo le best practice: formato verticale o quadrato, inserimento di copy-ad, sottotitoli e sound-on?",
      Choices: [
        "No, non le abbiamo mai seguite",
        "Sì, ma ne seguiamo solo alcune",
        "Sì, le seguiamo tutte sempre",
      ],
      answer: "",
      icon: QuizIcon,
      point: 0,
    },
    {
      question: "Quali formati utilizzi di solito per le tue campagne?",
      Choices: [
        "Solo formati statici o solo video",
        "Mix di formati statici e video",
        "Formati instant experience come vetrina interattiva, lookbook e collection",
      ],
      answer: "",
      icon: QuizIcon,
      point: 0,
    },
    {
      question:
        "Come hai risolto un calo di performance nelle tue campagne always-on?",
      Choices: [
        "Non ho mai sperimentato cali di performance",
        "Abbiamo modificato solo il set-up della campagna lasciando invariata la creatività",
        "Siamo intervenuti esplorando nuovi approcci creativi",
      ],
      answer: "",
      icon: QuizIcon,
      point: 0,
      locked: true,
    },
    {
      question:
        "Progettando le tue campagne, hai mai elaborato una strategia di crescita partendo dalla creatività?",
      Choices: [
        "No, mai",
        "Sì, ma non è stata efficace",
        "Sì e ne sono entusiasta",
      ],
      answer: "",
      icon: QuizIcon,
      point: 0,
    },
    {
      question:
        "Per migliorare i risultati delle tue campagne, adotti un approccio test&learn?",
      Choices: ["No, mai", "Sì, qualche volta", "Sì, quasi sempre"],
      answer: "",
      icon: QuizIcon,
      point: 0,
    },
  ]);
  const itemsRef = useRef([]);
  const [privacyChecked, setprivacyChecked] = useState(false);
  // select answer of each question
  const SelectAnswer = (index, answer) => {
    setQuestions(() => {
      return Questions.map((question, indx) =>
        indx === index
          ? {
              ...question,
              icon: QuizIcon,
              answer,
              point: question.Choices.indexOf(answer) + 1,
            }
          : question
      );
    });
    // to calculate points of user answers
    setAllPoints(() => {
      return Questions.map((question, indx) =>
        indx === index ? question.Choices.indexOf(answer) + 1 : question.point
      ).reduce((a, b) => a + b, 0);
    });
  };
  // handle Choice click ( when user click the answer )
  const HandleChoice = (e, index, choice) => {
    SelectAnswer(index, choice);
    let siblings = e.target.parentNode.childNodes;
    let arrSiblings = [...siblings];
    arrSiblings.forEach((el) => {
      el.classList.remove("selected");
      el.classList.add("not-selected");
    });
    e.target.classList.add("selected");
    swiperRef.current.allowSlideNext = true;
    swiperRef.current.slideNext();
    swiperRef.current.allowSlideNext = false;
    // remove lock icon
    swiperRef.current.slides[
      swiperRef.current.realIndex
    ].firstChild.firstChild.style.display = "none";
  };
  // reset questions
  const ResetQuiz = () => {
    nextRef.current.style.display = "none";
    SubmitBTN.current.classList.remove('Done-Submit');
    SubmitBTN.current.innerHTML = 'inviami il playbook'
    // update questions
    setQuestions(() =>
      Questions.map((question) => {
        return { ...question, answer: "", point: 0 };
      })
    );
   


    // preveint swipe
    setTimeout(() => {
      swiperRef.current.slideTo(0);
      swiperRef.current.allowSlideNext = false;
    }, 300);
    // remove selected and unselected class from all choices
    itemsRef.current.forEach((el) => {
      [...el.lastElementChild.children].forEach((ele) => {
        ele.classList.remove("selected");
        ele.classList.remove("not-selected");
      });
      // show lock icon
      el.firstChild.style.display = "block";
    });
  };
  // handle submit result 
  const SubmitResult = async () => {
    if(email.length &&email.match("[a-z0-9]+@[a-z]+.[a-z]{2,3}")){
      setDisableBTN(true)
      await fetch("https://creativity-unlocked-backend.webranking.biz/", {
        method: "POST", 
        body: JSON.stringify({form:'survey' , email}),
      })
        .then((response) => response.json())
        .then((data) => {
          setEmail('');
          setprivacyChecked(false);
          setError('');
          setDisableBTN(false)
          HandleDoneSubmit(SubmitBTN , 'iscrivimi alla newsletter' ,"INVIATO" , false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    if(!email.length || !email.match("[a-z0-9]+@[a-z]+.[a-z]{2,3}")) setError('Inserisci un indirizzo email valido')
  }
  return (
    <div className="QuizSlider">
      <Swiper
        allowSlideNext={false}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={10}
        onSlideChange={({ slides }) => {
          slides.forEach((slide, index) => {
            setTimeout(() => {
              // to allow and prevent swipe
              if (slide.classList.contains("swiper-slide-active")) {
                if (Questions[index] && Questions[index].answer.length > 5) {
                  swiperRef.current.allowSlideNext = true;
                  nextRef.current.style.display = "flex";
                } else {
                  swiperRef.current.allowSlideNext = false;
                  nextRef.current.style.display = "none";
                }
              }
              // to remove pagination if current slide is result
              if (
                swiperRef.current.slides[
                  swiperRef.current.realIndex
                ].firstChild.classList.contains("Result")
              ) {
                swiperRef.current.pagination.$el[0].style.display = "none";
                prevRef.current.style.display = "none";
              } else {
                swiperRef.current.pagination.$el[0].style.display = "block";
                prevRef.current.style.display = "block";
              }
            }, 200);
          });
        }}
        centeredSlides={true}
        navigation={{
          nextEl: ".next",
        }}
        onInit={(swiper) => {
          nextRef.current.style.display = "none";
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        pagination={{
          clickable: false,
        }}
        autoHeight={true}
        modules={[Pagination, Navigation]}
        className="quizSwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        }}
      >
        {Questions.map((question, index) => (
          <SwiperSlide key={index}>
            <div
              className="question"
              ref={(el) => (itemsRef.current[index] = el)}
            >
              {index != 0 && (
                <img src={LockedIcon} alt="locked" className="lockIcon" />
              )}
              <span className="caption flexCenterRow">
                <img
                  src={question.icon}
                  width="60px"
                  height="37px"
                  alt="quiz"
                />
                <strong>DOMANDA {index + 1}</strong>/6
              </span>
              <p className="paragraph">{question.question}</p>
              <div className="choices">
                {question.Choices.map((choice, choicIndx) => (
                  <div
                    className={`choice `}
                    key={choicIndx}
                    onClick={(e) => HandleChoice(e, index, choice)}
                    onMouseEnter={MouseEnteredLinks}
                    onMouseLeave={MouseLeave}
                  >
                    {choice}
                  </div>
                ))}
              </div>
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <div
            className={`Result ${
              Questions[Questions.length - 1].answer.length > 5 && "showResult"
            }`}
          >
            <div className="head">
              <img src={QuizIcon_dark} alt="quizIcon" />
              <h2>
                {allPoints <= 9
                  ? "Sei agli inizi, c’è tanto da imparare!"
                  : allPoints > 9 && allPoints <= 13
                  ? "Ci sei quasi, non mollare!"
                  : "Complimenti, le sai tutte!"}
              </h2>
              <p>
                {allPoints <= 9
                  ? "Potresti fare molto di più con i tuoi asset creativi: visual identity e contenuti di qualità sono la chiave per convertire il tuo pubblico."
                  : allPoints > 9 && allPoints <= 13
                  ? "Conosci la maggior parte delle regole creative per progettare ottime campagne, continua a sperimentare con i tuoi contenuti."
                  : "Per te la creatività non ha quasi più segreti: sai creare contenuti riconoscibili che assicurano il successo delle tue campagne."}
              </p>
            </div>

            {allPoints <= 9 ? (
              <p className="paragraph">
                Vuoi migliorare la performance delle tue campagne? Scarica
                subito il <strong> playbook di Creativity Unlocked</strong>
              </p>
            ) : allPoints > 9 && allPoints <= 13 ? (
              <p className="paragraph">
                Vuoi perfezionare il tuo approccio alla creatività? Scarica
                subito il
                <strong> playbook di Creativity Unlocked</strong>
              </p>
            ) : (
              <p className="paragraph">
                Vuoi avere tutte le best practice sempre a portata di mano?
                Scarica subito il
                <strong> playbook di Creativity Unlocked</strong>
              </p>
            )}
            <div className="Email">
              <label className="form-label" htmlFor="email">
                EMAIL*
              </label>
              <input className={error.length&&"errEmail"} type="email" id="email" placeholder="La tua email" value={email} onChange={(e) => setEmail(e.target.value) } />
              {error.length>1&& <p className="invalid-email">Inserisci un indirizzo email valido</p>}
            </div>
            <div className="checkbox-container">
              <input
                id="policy"
                className="hidden-checkbox"
                type="checkbox"
                onChange={(e) => setprivacyChecked(e.target.checked)}
              />
              <div
                className="styled-checkbox"
                onClick={() => setprivacyChecked(!privacyChecked)}
              >
                <svg
                  className={!privacyChecked ? "d-none" : "icon"}
                  viewBox="0 0 24 24"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </div>
              <label className="checkbox-label" htmlFor="policy">
                Voglio iscrivermi alla newsletter di Daimon e dichiaro di aver
                letto e accettato l
                <a
                  href="https://daimon.agency/privacy-policy-newsletter/"
                  rel="noreferrer"
                  target="_blank"
                  onMouseEnter={MouseEnteredLinks}
                  onMouseLeave={MouseLeave}
                >
                  'informativa Privacy
                </a>
              </label>
            </div>
            <button
              className="submit-button"
              onMouseEnter={MouseEnteredLinks}
              onMouseLeave={MouseLeave}
              ref={SubmitBTN}
              onClick={SubmitResult}
              disabled={DisabledBTN}
            >
              inviami il playbook
            </button>
            <p className="info">
              Cliccando “Inviami il playbook” dichiari di aver letto e accettato{" "}
              <a
                href="https://daimon.agency/privacy-policy/"
                rel="noreferrer"
                target="_blank"
                onMouseEnter={MouseEnteredLinks}
                onMouseLeave={MouseLeave}
              >
                l’Informativa Privacy
              </a>
            </p>
          </div>
          <div
            className="ResetQuiz flexCenterRow"
            onClick={ResetQuiz}
            onMouseEnter={MouseEnteredLinks}
            onMouseLeave={MouseLeave}
          >
            <span className="reset">
              Ricomincia <img src={Refresh} alt="Refresh Quiz" />
            </span>
          </div>
        </SwiperSlide>
        <div className="slider-arrows">
          <div
            ref={prevRef}
            className="arrow prev"
            onMouseEnter={MouseEnteredLinks}
            onMouseLeave={MouseLeave}
          >
            <img
              src={ArrowLeft}
              alt="previous-slide"
              height="20px"
              width="31px"
            />
            indietro
          </div>
          <div
            ref={nextRef}
            className="arrow"
            onMouseEnter={MouseEnteredLinks}
            onMouseLeave={MouseLeave}
          >
            avanti
            <img
              src={ArrowRight}
              alt="previous-slide"
              height="20px"
              width="31px"
            />
          </div>
        </div>
      </Swiper>
    </div>
  );
}
